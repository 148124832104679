.custom-toast {
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    color: #fff !important;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    justify-content: flex-start;
  }
  
  .custom-progress {
    background-color: #fff !important;
  }
  
  /* Success Toast */
  .custom-toast.success {
    background-color: #FF7006; 
    border-left: 5px solid #2e7d32;
  }
  
  /* Error Toast */
  .custom-toast.error {
    background-color: #f44336; 
    border-left: 5px solid #c62828;
  }
  
  /* Info Toast */
  .custom-toast.info {
    background-color: #2196f3; 
    border-left: 5px solid #1565c0;
  }
  
  /* Warning Toast */
  .custom-toast.warning {
    background-color: #ff9800; 
    border-left: 5px solid #ef6c00;
  }
  